<template>
  <div>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-file</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Dokumente</v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        single-line
        hide-details
        label="Suche"
        clearable
      />
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="warning"
      ></v-progress-linear>
    </v-app-bar>
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <v-list nav dense>
            <v-list-item :to="{ name: 'DocumentSearch' }">
              <v-list-item-icon
                ><v-icon>mdi-search-web</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>Suche</v-list-item-title>
                <v-list-item-subtitle
                  >Volltextsuche über alle Dokumente</v-list-item-subtitle
                >
              </v-list-item-content></v-list-item
            >
            <v-list-item :to="{ name: 'DocumentNoFiles' }">
              <v-list-item-icon
                ><v-icon>mdi-file-question-outline</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>fehlende Dateien</v-list-item-title>
                <v-list-item-subtitle
                  >Dokumente mit fehlender Datei</v-list-item-subtitle
                >
              </v-list-item-content></v-list-item
            >
          </v-list>
          <v-subheader>Kategorien</v-subheader>
          <v-treeview
            activatable
            :active.sync="active"
            dense
            :items="items"
            item-children="categories"
          >
            <template v-slot:prepend="{ open }">
              <v-icon>{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
            </template>
            <template v-slot:label="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:append="{ item, open }">
              <v-chip v-if="open" small>
                {{ item.documentsCount }}
              </v-chip>
              <v-chip v-else small>
                {{ item.allDocumentsCount }}
              </v-chip>
            </template>
          </v-treeview>
        </v-col>

        <v-col cols="8"> <router-view></router-view> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { formatDate } from 'common/utils/date.js';
import { debounce } from 'lodash';
export default {
  data() {
    return {
      items: [],
      active: [],
      loading: false,
      search: '',
    };
  },
  watch: {
    active(arr) {
      if (arr.length) {
        const newCatId = arr[0];
        if (this.$route.params.catId != newCatId) {
          this.$router.push({ name: 'Category', params: { catId: newCatId } });
        }
      }
    },
    async search() {
      this.doSearch();
    },
  },
  methods: {
    doSearch: debounce(async function () {
      if (!this.search || this.search.length < 3) {
        return;
      }
      this.$router.push({
        name: 'DocumentSearch',
        params: { search: this.search },
      });
    }, 500),
    formatDate,
  },
  async created() {
    if (this.$route.params.catId) {
      this.active.push(parseInt(this.$route.params.catId));
    }
    this.loading = true;
    this.items = await this.apiList({ resource: 'document/category' });
    this.loading = false;
  },
};
</script>
